import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";
import {
  About,
  Contact,
  Event,
  Expertise,
  Home,
  Resources,
  Team,
} from "./pages";
import Layout from "./layout/Layout";
import { Toaster } from "react-hot-toast";
import Publications from "./pages/Publications";
import Newsletters from "./pages/Newsletters";
import SinglePublicationPage from "./component/publication/SinglePublicationPage";
import SingleEventPage from "./component/events/SingleEventPage";
import SingleNewsletterPage from "./component/newsletter/SingleNewsletterPage";

function App() {
  return (
    <>
      <Toaster position="top-right" />
      <HashRouter>
        <Routes>
          <Route path="/">
            <Route element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/team" element={<Team />} />
              <Route path="/expertise" element={<Expertise />} />
              <Route path="/event">
                <Route index element={<Event />} />
                <Route path=":slug" element={<SingleEventPage />} />
              </Route>
              <Route path="/resources">
                <Route path="publication">
                  <Route index element={<Publications />} />
                  <Route path=":slug" element={<SinglePublicationPage />} />
                </Route>
                <Route path="newsletter">
                  <Route index element={<Newsletters />} />
                  <Route path=":slug" element={<SingleNewsletterPage />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<>Error no page here</>} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
