import React from "react";
import { General, Hero, Sectors } from "../component/expertise";
import { Ad } from "../component/team";
import { Contact } from "../component/home";
import { ScrollToTop } from "../lib/utils";

const Expertise = () => {
  ScrollToTop();
  return (
    <>
      <main className="teamBg">
        <section className="py-[120px] bg-black  text-white bg-opacity-80">
          <Hero />
        </section>
      </main>
      <section className=" pt-[120px] max-w-[1140px] mx-auto px-5 xl:px-0">
        <General />
      </section>
      <section className=" pb-[120px] pt-16 max-w-[1140px] mx-auto px-5 xl:px-0">
        <Sectors />
      </section>
      <section className=" max-w-[1140px] mx-auto px-5 xl:px-0">
        <Ad />
      </section>
      <section className="py-[50px] bg-[#f1f1f1] px-5 xl:px-0">
        <Contact />
      </section>
    </>
  );
};

export default Expertise;
