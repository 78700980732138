import React, { useEffect } from "react";
import { truncateText } from "../../lib/utils";
import { XHR, ImgUrl } from "../../app/request";

const General = () => {
  const [GpaData, setGpaData] = React.useState([]);

  const fetchData = () => {
    XHR("get", "/gpas")
      .then((res) => {
        setGpaData(res.data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const GPA = [
    {
      heading: "Corporate & business advisory services",
      desc: "Our Corporate and Business Advisory team provides and offers a wide range of corporate consultancy services for national and international businesses engaged in the Nigerian economy; this includes creative and innovative corporate and business solutions to fulfil their business goals successfully. The Firm's experience in litigation and arbitration, particularly concerning disputes involving business transactions and investments, has proven beneficial when handling corporate legal challenges. Our advisory team assesses the risks business transactions may pose and identifies the most effective strategies for risk management. The legal practice of corporate & business law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our corporate lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Civil Litigation",
      desc: "Under the leadership of the Firm’s Principal Counsel, Mr. Uchenna Ojembe, the Firm has amassed significant litigation experience spanning various legal areas and sectors. Over the years, our experience in civil litigation includes representing corporate entities, individual clients, and governmental institutions involved in complicated and challenging legal matters. We have handled and resolved tort claims, corporate, commercial, and business disputes, issues emanating from contractual agreements, and various civil disputes. In every matter, our strategy is influenced by the most recent and current legal developments under statutory legislation and judicial precedent. To accomplish the best possible outcome for our clients, we commit ourselves to represent our clients within the bounds of the law, the rules of conduct, and the practices of the Nigerian Bar. We also rely on our background and expertise in related or similar cases while bearing in mind the unique facts and goals of each case. The legal practice of civil litigation is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our civil litigation lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Corporate & commercial",
      desc: "Our commercial and corporate advising services are individually tailored for each client while considering the context of the commercial transaction or corporate project. The primary goal is to ensure the fulfilment of the client's commitments while achieving the overall purpose of the corporate or commercial transaction, which is why we adopt a unique strategy when advising and guiding our clients about this area. UOALC’s corporate and commercial advisory team holds an exceptional position in providing legal advice for a wide range of corporate and commercial transactions due to our experience in advising on such transactions in multiple areas of the Nigerian economy. The legal practice of commercial law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our corporate & commercial lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Corporate Governance & compliance",
      desc: "Board members, executives, employees, investors, shareholders, and stakeholders are mainly preoccupied with corporate governance challenges. This practice area team advises our Nigerian and international clients on handling challenging corporate governance rules in Nigeria, as well as creating and implementing strategies and procedures to avoid future corporate governance issues. We also assist clients with such issues by offering the appropriate remedial measures. Our clients are in the best position to maintain good standing with the relevant laws and regulatory authorities as we guide them in responding to regulatory inquiries, improving compliance procedures, and establishing vital compliance programs while providing recent updates.",
    },
    {
      heading: "Criminal litigation",
      desc: "The firm defends and prosecutes legal actions on behalf of its clients in Nigerian courts and tribunals. Our principal goal is to ensure that all criminal matters, including those resulting from clients' business and commercial activities, are appropriately addressed with the least amount of time and money expended by clients. We represent our clients within the bounds of law in regard to many aspects of criminal matters, such as arrest, bail, and appeals, while ensuring the maintenance of their constitutional safeguards before, during, and after trial. Our strategy while prosecuting or defending on behalf of our clients in such criminal matters is influenced by the most recent and current legal developments under statutory legislation and judicial precedent. The legal practice of criminal litigation is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our criminal litigation lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Labour and employment",
      desc: "Our labour and employment law specialists provide legal assistance to aid our clients in accomplishing their company's goals while remaining legally compliant with Nigerian labour law standards. We also provide proper planning, guidance, and remedies to help you prevent possible disruptive employee conflicts within the workplace. Where a legal dispute arises, our skilled lawyers actively defend our client's rights in court, as well as in mediation and arbitration processes, depending on the unique facts of each case. We have represented clients in issues concerning – insurance of liability, incentive schemes for employees, pension schemes for retirement, expatriate employee scheme and taxes, drafting and preparing employment agreements, drafting of workplace rules and codes of conduct, drafting and enforcing 'non-compete' agreements and settlement of disputes through arbitration and mediation if the need arises. The legal practice of labor law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our labour & employment lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Finance & tax",
      desc: "Our Finance and Tax advisory group is a highly specialized team experienced in providing legal services and advice on contract negotiations, tax compliance, and tax risk analysis for taxpayers, lenders and borrowers, financial institutions, and corporate clients in various national and international sectors or industries. The guidance offered in this area by our experts is focused on improving and monitoring the performance of our client’s obligations while avoiding and minimizing the financial risk of their economic activities. We are globally renowned for our excellent work in representing leading businesses in tax- related negotiations, arbitrations, and litigation. We are also responsible for protecting the interests of our clients and other taxpayers in any dispute with the various tax authorities; this is due to our expertise in corporate finance, project financing, real estate finance, structured finance, infrastructure finance, asset finance, capital markets, private equity, and other areas of finance. The legal practice of finance law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our Finance lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Intellectual property",
      desc: "Our intellectual property legal team is exceptionally skilled in acquiring and safeguarding your valuable intellectual property rights nationally and globally. We are committed to assisting our clients in developing, processing, and protecting their intellectual property by offering unparalleled and extensive insight on intellectual property issues. Our IP practice group handles services such as clearance searches, drafting, reviewing, amending, processing, and filing trademark, copyright, and patent applications while protecting their uses, enforcement, renewal, and cancellation. We also register domain names and products with the appropriate agencies in Nigeria, and we are dedicated to representing our clients in intellectual property rights litigation and dispute resolution. The legal practice of intellectual property law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our IP lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Investment trade & investment",
      desc: "Our investment advisory team counsel Nigerian and foreign investors in various sectors and industries on – business incorporations, foreign participation, applications for exemptions, protection of investors, stakeholders, and shareholders' rights, investment and tax contract protection, and application for licences and permits. We also prepare, draft, file, and manage documents necessary for investment trade transactions within and beyond Nigeria's borders. The legal practice of investment law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our investment amp; trade lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Media & entertainment",
      desc: "Our media and entertainment advisory team assists clients in every area of this industry, which is evolving, growing, and generating a significant impact. On a wide range of regulatory and legal matters, we offer legal advice to major stakeholders in the media and entertainment sector. Among the services we offer are - guidance on consumer rights, cyber security and data protection, competition issues, regulatory and compliance issues, negotiating business arrangements, drafting and reviewing business contracts and transactions, providing various financing tactics, privatization, and handling the application and approval of regulatory licence or permits. Our seasoned lawyers have an excellent track record and are aware of the difficulties faced by clients working in this industry, which is why we develop creative and affordable alternatives to our client's business needs. We have negotiated complex transactions on behalf of firms and investors in this industry, including notable media and entertainment firms, celebrities, shareholders, and others in Nigeria and internationally. The legal practice of media & entertainment law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our media & entertainment lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Mergers & Acquisition",
      desc: "Mergers and acquisitions have consistently been an important strategic choice for companies, businesses, and organizations. Our merger and acquisition advisory team guides our clients in assessing, evaluating, and understanding the various options available to them. We take pride in having provided important strategic advice on many mergers and acquisition operations in Nigeria, reflecting our renowned competence in offering advice on such transactions in the industry; this is a result of the experience of our advisory team, which continues to provide clients with prompt, creative, and practical legal solutions as they benefit from our expertise in handling their most complicated and challenging transactions.",
    },
    {
      heading: "Public policy & regulation",
      desc: "Our public policy and regulation team can utilize and capitalize on the convergence between public policy goals and private company objectives due to our in-depth understanding of Nigeria's legal and regulatory framework. We use advocacy and accurate research findings to guide our clients in regard to public policy and offer them valuable solutions, thus enhancing the public policy process and providing sound legal advice for our clients. The legal practice of public policy & regulation law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our public policy lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Family law",
      desc: "UOALC lawyers are dedicated to offering legal counsel in all aspects of Family Law. Our family law experts provide reliable guidance on amicable separation and divorce, custody and visitation disputes, and other complex family property disputes. We offer beneficial advice and advocacy by utilizing our experience and skills. We know the value of settling disputes quickly and efficiently while avoiding litigation. Our lawyers will work hand-in-hand with you to resolve family disputes without going to court through various means, including Alternative Dispute Resolution. We also provide efficient alternatives while paying attention to the unique facts of each case. If legal action is required, you will be represented by the best to achieve the best possible outcome. Our services under family law also cover – divorce and separation, adoption, child custody and support, mediation and resolution, domestic violence, family property, wills and inheritance, probate and estate administration, and spousal maintenance. The legal practice of family law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our family lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Recovery of debt & insolvency",
      desc: "Our recovery of debt and insolvency practice group employs expertise and innovation to successfully recover debt for our clients by navigating Nigerian insolvency legislation to safeguard client's valuable assets and resources and maximize their recovery. As counsel to major financial institutions, investors, and other stakeholders, our skilled financial management lawyers are aware that such financial transactions are private, urgent, and sensitive matters, and we take pride in being attentive to the challenges and concerns of our clientele. We have completed several key missions on debt recovery for many individuals and Corporations in Nigeria by offering proactive, innovative, and efficient remedies to the most complex situations. UOALC's experts in insolvency law handle and counsel clients on a broad spectrum of insolvency and creditors' rights disputes, whether simple or complicated. We have represented landlords, private and public investors, secured and unsecured creditors, corporate debtors, and bankruptcy trustees.",
    },
    {
      heading: "Sports Law",
      desc: "At UOALC, our team of sport lawyers represent athletes, industry boards, teams, leagues and other sports organizations in civil and criminal matters in court. We provide legal advice and guidance to clients and help them understand broadcast rights, trademarks and endorsement. We also execute and negotiate player and team contracts, negotiate sponsorship deals, resolve labor and contract disputes, handle false accusations and speak with the media on behalf of our clients. Rather than leaving your deal to best guesses, UOALC’s sports lawyers will ensure that you do not make any legal mistakes. This approach is most practical when managing your career, team or organization.",
    },
  ];

  const [expandedPosts, setExpandedPosts] = React.useState([]);

  const handleToggle = (postId) => {
    if (expandedPosts.includes(postId)) {
      setExpandedPosts(expandedPosts.filter((id) => id !== postId));
    } else {
      setExpandedPosts([...expandedPosts, postId]);
    }
  };
  return (
    <section>
      <div className="max-w-sm mb-5 flex items-center justify-between space-x-5">
        <h6 className="shrink-0 font-semibold">Expertise</h6>
        <span className="h-[2px] w-full bg-[#cfb66c]"></span>
      </div>
      <div className="max-w-sm">
        <h1 className="md:text-4xl text-3xl font-bold mb-10 sideFont">
          General Practice Areas
        </h1>
      </div>
      <main className="grid lg:grid-cols-3 grid-cols-2 gap-5">
        {GpaData.map((item, index) => (
          <div className="p-4 shadow-md border" key={index}>
            <div>
              <img
                src={`${ImgUrl}gpas/${item.photo}`}
                alt=""
                className="w-[320px] mb-3 mx-auto"
              />
            </div>
            <h1 className="md:text-xl text-sm mb-2 font-semibold uppercase max-w-[15rem]">
              {item.title}
            </h1>
            <p className="font-medium mb-10 text-[10px] md:text-[13px]">
              {expandedPosts.includes(index)
                ? item.body
                : truncateText(item.body, 150)}{" "}
              <span onClick={() => handleToggle(index)}>
                {expandedPosts.includes(index) ? (
                  <span className="text-[#cfb66c] cursor-pointer font-semibold">
                    {" "}
                    hide{" "}
                  </span>
                ) : (
                  <span className="text-[#cfb66c] cursor-pointer font-semibold">
                    show more
                  </span>
                )}
              </span>
            </p>
          </div>
        ))}
      </main>
    </section>
  );
};

export default General;
