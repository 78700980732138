import React from "react";
import { one, two } from "../../assets/about";
import { ImgUrl, XHR } from "../../app/request";
import { useEffect } from "react";
import { truncateText } from "../../lib/utils";

const Cards = () => {
  const [aboutUs, setAboutUs] = React.useState([]);
  const fetchAbout = () => {
    XHR("get", "/aboutUsAll")
      .then((res) => {
        setAboutUs(res.data);
        // console.log(res.data)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchAbout();
  }, []);

  function isEven(number) {
    return number % 2 === 0;
  }
  return (
    <>
      <section>
        {/* Loop */}
        {aboutUs.map((about, index) => {
          const { paragraph, photo } = about;
          return (
            <div className="grid md:grid-cols-2 gap-5 py-[50px]">
              <div
                className={`flex flex-col justify-center items-center md:items-start ${
                  isEven(index) || "order-first md:order-last"
                }`}
              >
                <div className="">
                  <p className="max-w-md font-medium">{paragraph}</p>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <img
                  src={`${ImgUrl}abouts/${photo}`}
                  alt=""
                  className="w-[26rem] mx-auto"
                />
              </div>
            </div>
          );
        })}
        {/* Loop end*/}
      </section>
    </>
  );
};

export default Cards;
