import React from "react";
import { Ad, Cards, Cards2, Hero, Numbers } from "../component/about";
import { Contact, Team } from "../component/home";
import { ScrollToTop } from "../lib/utils";

const About = () => {
  ScrollToTop();
  return (
    <>
      <main className="aboutBg">
        <section className="lg:py-[120px] pt-[120px] md:pb-[60px] pb-[20px] bg-black  text-white bg-opacity-80">
          <Hero />
        </section>
      </main>
      <section className="py-5 bg-[#d6bc6d] text-white  px-5 xl:px-0">
        <Numbers />
      </section>
      <section className=" max-w-[1140px] mx-auto px-5 xl:px-0">
        <Cards />
      </section>
      {/* <section className=" max-w-[1140px] mx-auto px-5 xl:px-0">
        <Cards2 />
      </section> */}
      <main className="teamBg">
        <section className="py-[120px] bg-black text-white bg-opacity-80 px-5 xl:px-0">
          <Team />
        </section>
      </main>
      <section className=" max-w-[1140px] mx-auto">
        <Ad />
      </section>
      <section className="py-[50px] bg-[#f1f1f1] px-5 xl:px-0">
        <Contact />
      </section>
    </>
  );
};

export default About;
