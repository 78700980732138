import React from "react";
import { Contact } from "../component/home";
import {
  ContactUs as ContactComponent,
  Map,
  Suggestion,
} from "../component/contact";
import { ScrollToTop } from "../lib/utils";

const ContactUs = () => {
  ScrollToTop();
  return (
    <>
      <section className="py-[50px] pt-[150px]  px-5 xl:px-0">
        <ContactComponent />
      </section>
      <section>
        <Map />
      </section>
      <section className="pt-[50px] bg-[#f1f1f1] px-5 xl:px-0">
        <Contact />
      </section>
      {/* <section className="pb-[50px] bg-[#f1f1f1] px-5 xl:px-0">
        <Suggestion />
      </section> */}
    </>
  );
};

export default ContactUs;
