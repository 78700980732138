import React from "react";
import CountUp from "react-countup";
import { XHR } from "../../app/request";

const Numbers = () => {
  const [counters, setCounters] = React.useState({});
  const fetchCounter = () => {
    XHR("get", "/counters")
      .then((res) => {
        setCounters(res.data[0]);
        console.log(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    fetchCounter();
  }, []);
  return (
    <section className="max-w-[1140px] mx-auto">
      <div className="flex justify-around text-center px-5 xl:px-0">
        <div className="flex items-center space-x-5">
          <h1 className="lg:text-5xl md:text-3xl text-2xl font-semibold sideFont">
            <CountUp start={0} end={counters?.client} duration={5} delay={1} />+
          </h1>
          <p className="max-w-[5rem] md:text-sm text-xs text-start font-semibold sideFont">
            Satisfied Clients
          </p>
        </div>
        <div className="flex items-center space-x-5">
          <h1 className="lg:text-5xl md:text-3xl text-2xl font-semibold sideFont">
            <CountUp start={0} end={counters?.case} duration={5} delay={1} />+
          </h1>
          <p className="max-w-[5rem] md:text-sm text-xs text-start font-semibold sideFont">
            Cases handled
          </p>
        </div>
      </div>
    </section>
  );
};

export default Numbers;
