import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, FreeMode, Pagination } from "swiper";
import { GoQuote } from "react-icons/go";
import { BsFillStarFill } from "react-icons/bs";

const Testimonials = ({ content }) => {
  const testimonials = [
    {
      testimonial:
        "Your Firm has been providing our company with legal services for the past three years and you have never failed to deliver. Your lawyers are professional, efficient, and always get the job done. They were always available when needed, represented us in court, and delivered excellent results. Your team also handled corporate agreements and negotiated great deals for our company. We have never been disappointed and we are still enjoying the benefits of your services. We will keep recommending your Firm to others.",
      name: "Dr. Timothy Okon",
      position: "Director, Teno Energy Resources Ltd.",
    },
    {
      testimonial:
        "I was really impressed with the level of service and professionalism UOALC offered us. We were able to easily reach them both physically and virtually. The lawyers were very friendly and helpful and they were always there to help explain our options and important decisions for our company’s contracts. They handled all our business agreements and provided us with all the information we needed. We would definitely recommend the Firm to companies looking for great lawyers.",
      name: "-	Mrs. Yohanna Sunwar ",
      position: "Director of Finance, Energy Learning Solutions Ltd.",
    },
    {
      testimonial:
        "My friend referred me to your Firm after I was criminally charged. I was very scared and thought my life was over but you reassured me and made my case a priority immediately. You were very straightforward and told me what to expect every step of the way. Even though it was a very tough time for my family and me, you gave us hope and fought hard for me. Your team of lawyers also made the entire process so much easier. I am grateful that I’m no longer in custody. Thank you, Sir.",
      name: "Mr. Steven Danjuma ",
    },
    {
      testimonial:
        "I have always heard that court processes are very tedious and time-consuming; however, UOALC made it easier for me. They kept me updated on every stage of my case and when it was time for trial, the lawyers made me feel at ease, took control on my behalf, and communicated with me in the courtroom. They were able to achieve a favourable result in the trial, helping me feel safe again. I trust this Firm to handle any serious dispute you may have.",
      name: "Mrs. Margaret Adeyanju",
    },
    {
      testimonial:
        "I am grateful for your Firm's assistance in the successful administration of my family's estate. After my father passed, it was very hard for my family. I reached out to your Firm to help recover the death benefits of my father, regardless of the oppression I got from extended family members and in-laws. Your amazing team of lawyers were able to settle my family disputes peacefully, leaving everyone happy and satisfied. Please accept my sincerest gratitude for all you have done for me and my family. Words cannot explain how grateful we are to you and your associates. I will certainly recommend your Firm.",
      name: "Mr. Nelson Ogene",
    },
    {
      testimonial:
        "As a small business owner, I was worried about the difficult process of starting a business, but their legal team assisted me in registering my company and guided me through each step until I was completely satisfied.  Also, their legal fees are affordable and I definitely got value for my money.",
      name: "Mrs. Deborah Eyo ",
      position: "Proprietor, Debandy Empire",
    },
    {
      testimonial:
        "Your Firm assisted me with my child custody issues. Your lawyers are absolutely fantastic and did a great job in calmly explaining every situation in detail to me and answering all of my questions. I really appreciate your timely response to my case. You have also been a very patient and trustworthy person. I remain grateful.",
      name: "Mrs. Isioma Agu",
    },
    {
      testimonial:
        "I made the wrong bank transfer and I was really concerned about getting my money back. I contacted UOALC immediately and two lawyers were assigned to my case. They represented me in court and I got my money back within a week! I am very impressed with their legal services and I admire their professionalism.",
      name: "Mrs. Theodora Odinenu",
    },
    {
      testimonial:
        "Personally, I prefer to resolve issues without getting lawyers involved but I had a case I was unable to resolve alone. UOALC was recommended by a friend of mine and their lawyers turned out to be quite reliable, intelligent, and caring. They came together to resolve my legal issues quickly, helping me to win my case and recover a lot of money from debtors. I am very pleased with their services and they remain the best choice for me.",
      name: "Mr. Raymond Nwuzor ",
    },
    {
      testimonial:
        "I reached out to UOALC for legal advice through virtual consultation and they were very helpful. Even though I wasn’t in the country at the time, they responded to all my questions and were very helpful with all my trademark and copyright problems. I was really impressed with the results and would not hesitate to use them again.",
      name: "-	Mr. Wisdom Iheze ",
      position: "Director, 2017 Zeelux Inv. Ltd.",
    },
    {
      testimonial:
        "From my first consultation, I knew my case will be handled professionally. Your lawyers handled the entire process smoothly and kept me informed on every step, and all that was required of me was to show up in court on the hearing date. They briefed me on what to expect, what to say, and also let me know my next steps. I am sincerely grateful for all your hard work and commitment to my case. Thank you.",
      name: "-	Mrs. Ifeoma Eze",
    },
    {
      testimonial:
        "I was worried about pursuing any legal action in court, but my concerns were eased during my virtual consultation. The lawyer I spoke to took his time to explain the entire process and how they could help me. I appreciate their sincerity and commitment to representing me. The team of lawyers assigned to my case maintained constant communication with me throughout the trial until my case was successfully resolved in my favour.",
      name: "-	Mr. Joshua Adeoye",
    },
    {
      testimonial:
        "Unfortunately, I have experienced two divorces in my life. But, in each of them, I was able to turn to your Firm for advice and guidance. It was a very challenging time for me but your expertise helped greatly. Thank you!",
      name: "-	Mrs. Chinyere Iheanacho",
    },
    {
      testimonial:
        "I had a wonderful experience with UOALC. I needed some legal advice and wasn't sure how to go about it. I found UOALC through a Google search and was impressed with the information on their website. I decided to reach out and was connected with Barrister Uche immediately. He was so helpful and reliable. His Firm has successfully represented me in two court cases and I am satisfied with the results.",
      name: "-	Mrs. Aderonke Atanda",
    },
    {
      testimonial:
        "Barrister Uche is a great lawyer with a lot of experience, and his team of qualified lawyers are very dedicated. I had the pleasure of dealing with lawyers who were very upfront and realistic about my situation and what to expect. They were very responsive to all of my questions and took the time to explain things in a way that was easy to understand. I got my claim successfully with interest for which I am very grateful. If you need legal assistance, they are the perfect law firm for you.",
      name: "-	Mr. Shehu Mohammed Wasiu",
      position: "",
    },
    {
      testimonial:
        "UOALC is a reliable law firm. I have worked with them on different occasions and I have always been impressed with their professionalism and expertise. They have a proven track record of success and are always able to get clients out of difficult situations. I highly recommend the Firm and its amazing team to anyone in need of legal services.",
      name: "-	Barr. Eddidiong Bassey",
    },
    {
      testimonial: "I love you so much!",
      name: "Mr. Segun Adeyemo",
    },
  ];

  const Stars = () => {
    return (
      <div className="flex space-x-1 text-[#cfb66c]">
        <BsFillStarFill size={15} />
        <BsFillStarFill size={15} />
        <BsFillStarFill size={15} />
        <BsFillStarFill size={15} />
        <BsFillStarFill size={15} />
      </div>
    );
  };
  return (
    <section id="testimonials">
      <div className="max-w-sm mb-2 flex items-center justify-between space-x-5">
        <h6 className="shrink-0 font-semibold">Testimonials</h6>
        <span className="h-[2px] w-full bg-[#cfb66c]"></span>
      </div>

      {/* <div className="max-w-sm">
        <h1 className="text-4xl font-bold mb-10 sideFont">
          Our Happy Clients{" "}
        </h1>
      </div> */}

      <p className="text-sm font-semibold text-center max-w-2xl mx-auto my-5">
        {content}
      </p>

      <Swiper
        spaceBetween={10}
        slidesPerView={"auto"}
        autoplay={{
          delay: 15000,
          disableOnInteraction: false,
        }}
        loop={true}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, FreeMode, Pagination]}
        breakpoints={{
          100: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 2,
          },
          1008: {
            slidesPerView: 2.5,
          },
        }}
      >
        {testimonials.map((item, i) => (
          <SwiperSlide>
            <div className="bg-white md:max-w-[30rem]">
              <div className="mt-5 border-2 border-gray-300 shadow flex flex-col px-10 py-16 h-[55vh] relative">
                <p className="leading-6 flex-1 text-gray-700 font-semibold text-xs tracking-wide">
                  {item.testimonial}
                </p>
                <GoQuote
                  className="absolute top-14 left-2 text-[#f3ebd3]"
                  size={30}
                />
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-[#cfb66c] text-sm font-bold">
                      {item.name}
                    </p>
                    <p className="text-[#cfb66c] text-[10px] font-bold">
                      {item.position && item.position}
                    </p>
                  </div>
                  {<Stars />}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Testimonials;
