import React, { useEffect, useState } from "react";
import { XHR } from "../../app/request";
import { Link } from "react-router-dom";
import { ScrollToTop } from "../../lib/utils";

const Publications = () => {
  const [publicationData, setPublicationData] = useState([]);

  // ==================================================================================================
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const fetchPublicationData = (page) => {
    XHR("get", `/publications?page=${page}`)
      .then((res) => {
        setPublicationData(res.data);
        setTotalPages(res.data.last_page);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    fetchPublicationData(currentPage);
  }, [currentPage]);
  ScrollToTop();
  return (
    <>
      <section className="my-10">
        <div className="max-w-sm mb-5 flex items-center justify-between space-x-5">
          <h6 className="shrink-0 font-semibold">Resources</h6>
          <span className="h-[2px] w-full bg-[#cfb66c]"></span>
        </div>
        <div className="max-w-sm">
          <h1 className="text-4xl font-bold mb-10 sideFont">Publications</h1>
        </div>
        {publicationData?.data?.length > 0 ? (
          <>
            <main className="cursor-pointer  gap-5">
              {publicationData?.data.map((item, index) => (
                <div
                  className="p-4 mb-5 hover:scale-[1.03] hover:shadow-sm duration-200 ease-linear transition"
                  key={index}
                >
                  <Link
                    to={`/resources/publication/${item.slug}`}
                    className="md:text-xl hover:text-[#cfb66c]  text-lg mb-1 font-semibold uppercase"
                  >
                    {item.title}
                  </Link>
                </div>
              ))}{" "}
            </main>
          </>
        ) : (
          <div className="md:text-xl text-lg mb-1 h-[20vh] pt-16 font-semibold uppercase">
            No Publications Listed
          </div>
        )}
        <div className="my-10 flex justify-between items-center">
          <div
            className={`${currentPage > 1 ? "newButton" : "disabled"}`}
            onClick={prevPage}
          >
            Previous
          </div>
          <div
            className={`${currentPage < totalPages ? "newButton" : "disabled"}`}
            onClick={nextPage}
          >
            Next
          </div>
        </div>
      </section>
    </>
  );
};

export default Publications;
