import React from "react";

const Hero = () => {
  return (
    <section className="max-w-[1140px] mx-auto flex justify-center items-center">
      <div className="px-5 xl:px-0 grow">
        <div className="max-w-2xl mb-5 mx-auto flex items-center justify-between space-x-10">
          <h6 className="shrink-0 font-semibold text-4xl">About Us</h6>
          <span className="h-[3px] w-full bg-[#cfb66c]"></span>
        </div>
        <div className=" md:h-[40vh] flex justify-center  mt-5">
          <div className="max-w-2xl text-center">
            <h1 className="lg:text-[3.5rem] md:text-[2.5rem] text-[2rem] font-semibold mb-5 sideFont">
              Welcome to Uche Ojembe & Associates Legal Consult
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
