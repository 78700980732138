import React from "react";
import { Anthony, Madu, Uche } from "../../assets/people";
import { Link } from "react-router-dom";
import { ImgUrl, XHR } from "../../app/request";

const Team = () => {
  const [team, setTeam] = React.useState([]);
  const fetchTeam = () => {
    XHR("get", "/teams")
      .then((res) => {
        setTeam(res.data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    fetchTeam();
  }, []);

  const displayTeam = team.slice(0, 3);
  const [homePage, setHomePage] = React.useState({});
  const fetchHome = () => {
    XHR("get", "/homepages")
      .then((res) => {
        setHomePage(res.data[0]);
        // console.log(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    fetchHome();
  }, []);
  return (
    <section className="max-w-[1140px] mx-auto">
      <div className="grid lg:grid-cols-5 gap-5">
        <div className="lg:col-span-2">
          <div className="max-w-sm mb-5 flex items-center justify-between space-x-5">
            <h6 className="shrink-0 font-semibold">Our Team</h6>
            <span className="h-[2px] w-full bg-[#cfb66c]"></span>
          </div>

          <div className="max-w-sm">
            <h1 className="text-4xl font-bold mb-10 sideFont">
              {homePage?.team_title}
            </h1>
          </div>

          <div className="max-w-sm mb-16 font-medium">
            <p className="mb-5">{homePage?.team_content}</p>
          </div>
        </div>
        <div className="lg:col-span-3">
          <section className="grid xl:grid-cols-3 lg:grid-cols-2 gap-5 md:grid-cols-3 grid-cols-2">
            {displayTeam.map((member, index) => {
              const { photo, position, name } = member;
              return (
                <div key={index} className=" flex items-center justify-center">
                  <div className="w-56">
                    <img src={`${ImgUrl}teams/${photo}`} alt="" className="" />
                    <div className="p-2 text-center bg-white text-black">
                      <h2 className="uppercase font-bold">{name}</h2>
                      <p className="uppercase text-xs">{position}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </section>
          {displayTeam.length > 0 && (
            <div className="mt-5">
              <Link to="/team" className="button">
                See All
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Team;
