import React from "react";
import aboutImage from "../../assets/home/Group.png";
import { Link } from "react-router-dom";
import { ImgUrl } from "../../app/request";

const About = ({ title, subTitle, photo }) => {
  return (
    <section>
      <div className="grid lg:grid-cols-5 gap-5">
        <div className="lg:col-span-2">
          <div className="max-w-sm mb-5 flex items-center justify-between space-x-5">
            <h6 className="shrink-0 font-semibold">About Us</h6>
            <span className="h-[2px] w-full bg-[#cfb66c]"></span>
          </div>

          <div className="lg:max-w-sm max-w-xl">
            <h1 className="text-4xl font-bold mb-10 sideFont">{title}</h1>
          </div>

          <div className="lg:max-w-sm max-w-xl mb-16 font-medium">
            <p className="mb-5">{subTitle}</p>
          </div>

          <div>
            <Link to="/about" className="button">
              Read More
            </Link>
          </div>
        </div>
        <div className="lg:col-span-3 flex justify-center items-center">
          <div className="relative">
            <img
              src={`${ImgUrl}homepage/${photo}`}
              alt=""
              className="w-[29rem] z-20 h-[22rem]"
            />
            <div className="w-[29rem] hidden md:block absolute top-3 right-2 h-[22rem] -z-10 bg-[#cfb66c]"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
