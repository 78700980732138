import React from "react";

const Ad = () => {
  return (
    <section>
      <div className=" py-[120px]">
        <div className="flex flex-col justify-center items-center">
          <h1 className="lg:text-[3rem] text-[2rem] text-center font-semibold mb-5 sideFont">
            Get Professional Legal Services from our Team of Seasoned Barristers
            and Solicitors
          </h1>
        </div>
      </div>
    </section>
  );
};

export default Ad;
