import React from "react";

const Map = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3940.1239031916407!2d7.438047373772458!3d9.052461288623832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sSuite%20006%2C%20Swift%20Plaza%2C%20Plot%20318%2C%20Agunwa%20Anaekwe%20Street%2C%20Wuye%20District%2C%20Abuja.!5e0!3m2!1sen!2sng!4v1684913603660!5m2!1sen!2sng"
        width="100%"
        height="450"
        // style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
