import React from "react";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="p-4 sm:p-6 bg-black text-white">
        <div className=" mx-auto mt-5 mb-14 max-w-[1140px]">
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2 md:grid-cols-4 ">
            {/* logo */}
            <div className="mb-6 md:mb-0 md:w-52 flex justify-center items-center">
              <a href="/" className="flex items-center">
                <Link to="/" className="">
                  <img src={logo} alt="" className="w-40" />
                </Link>{" "}
              </a>
            </div>
            {/* links */}
            <div>
              <h2 className="mb-6 text-[#cfb66c] font-semibold  uppercase ">
                Useful Links
              </h2>
              <ul className="text-gray-200">
                <li className="leading-8">
                  <Link to="/about" className="hover:underline">
                    About UOALC
                  </Link>
                </li>
                <li className="leading-8">
                  <Link to="/expertise" className="hover:underline">
                    Expertise
                  </Link>
                </li>
                {/* <li className="leading-8">
                  <Link to="/#testimonials" className="hover:underline">
                    Client Testimonials
                  </Link>
                </li> */}
                <li className="leading-8">
                  <Link to="/contact" className="hover:underline">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            {/* keeping up with */}
            <div>
              <h2 className="mb-6  text-[#cfb66c] font-semibold  uppercase">
                Keep Up With
              </h2>
              <ul className="text-gray-200">
                <li className="leading-8">
                  <Link to="/event" className="hover:underline ">
                    Upcoming events
                  </Link>
                </li>
                <li className="leading-8">
                  <Link to="/resources" className="hover:underline">
                    Recent Updates
                  </Link>
                </li>
              </ul>
            </div>
            {/* follow */}
            <div>
              <h2 className="mb-6 text-sm font-semibold  uppercase  text-[#cfb66c]">
                Follow Us
              </h2>
              {/* <ul className="text-gray-200 ">
                <li className="">
                  <a href="/contact" className="hover:underline">
                    Customer support
                  </a>
                </li>
                <li>
                  <a href="/login" className="hover:underline">
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="/register" className="hover:underline">
                    Twitter
                  </a>
                </li>
                <li>
                  <a href="/success" className="hover:underline">
                    Facebook
                  </a>
                </li>
              </ul> */}
              <div className="">
                <div>
                  <ul className="grid grid-cols-3 md:grid-cols-5 text-white md:flex-row gap-5 ">
                    <p className="tracking-tight block cursor-pointer transition-colors duration-300 text-2xl">
                      <a target="_blank" href="https://facebook.com/uoalc">
                        <BsFacebook />
                      </a>
                    </p>
                    <p className=" tracking-tight block cursor-pointer text-2xl">
                      <a target="_blank" href="https://instagram.com/uoalc_law">
                        <BsInstagram />
                      </a>
                    </p>
                    <p className=" tracking-tight block cursor-pointer text-2xl">
                      <a target="_blank" href="https://twitter.com/uoalc_law">
                        <BsTwitter />
                      </a>
                    </p>
                    <p className=" tracking-tight block cursor-pointer text-2xl">
                      <a target="_blank" href="#">
                        <BsLinkedin />
                      </a>
                    </p>
                    <p className=" tracking-tight block cursor-pointer text-2xl">
                      <a target="_blank" href="#">
                        <BsYoutube />
                      </a>
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 " /> */}
        <div className="sm:flex sm:items-center sm:justify-center my-6 lg:my-8">
          <span className="text-sm text-white sm:text-center">
            Copyright © 2023 – Uche Ojembe & Associates Legal Consult (UOALC)
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
