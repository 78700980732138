import React, { useEffect } from "react";
import { ImgUrl, XHR } from "../../app/request";
import { ScrollToTop, truncateText } from "../../lib/utils";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

const EventCards = () => {
  const [EventData, setEventData] = React.useState([]);
  // MODAL FUNCTIONALITY
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setOpenModal(true);
  };
  // END MODAL FUNCTIONALITY

  // THE SHOW MORE FUNCTIONALITY
  const [showAll, setShowAll] = React.useState(false);

  // Function to handle the button click
  const handleButtonClick = () => {
    setShowAll(true);
  };

  // Slice the array based on the showAll state
  const displayedData = showAll ? EventData : EventData.slice(0, 2);
  // END SHOW MORE FUNCTIONALITY

  const fetchData = (page) => {
    XHR("get", `/events?page=${page}`)
      .then((res) => {
        setEventData(res.data.data);
        setTotalPages(res.data.last_page);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const [expandedPosts, setExpandedPosts] = React.useState([]);

  const handleToggle = (postId) => {
    if (expandedPosts.includes(postId)) {
      setExpandedPosts(expandedPosts.filter((id) => id !== postId));
    } else {
      setExpandedPosts([...expandedPosts, postId]);
    }
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);
  ScrollToTop();
  return (
    <>
      <section>
        <div className="max-w-sm mb-5 flex items-center justify-between space-x-5">
          <h6 className="shrink-0 font-semibold">Events</h6>
          <span className="h-[2px] w-full bg-[#cfb66c]"></span>
        </div>
        <div className="max-w-sm">
          <h1 className="text-4xl font-bold mb-10 sideFont">Event</h1>
        </div>
        {displayedData.length > 0 ? (
          <>
            <main className="grid sm:grid-cols-2 md:grid-cols-3 gap-5">
              {displayedData.map((item, index) => (
                // <section>
                //   <div className="p-4 shadow-md border" key={index}>
                //     <h1 className="md:text-xl text-lg mb-1 font-semibold uppercase">
                //       {item.title}
                //     </h1>
                //     <p className="font-medium mb-5 text-sm md:text-base">
                //       {/* {item.body} */}
                //       {expandedPosts.includes(index)
                //         ? item.body
                //         : truncateText(item.body, 250)}
                //     </p>
                //     <p
                //       onClick={() => handleItemClick(item)}
                //       className="text-[#cfb66c] cursor-pointer"
                //     >
                //       Read
                //     </p>
                //   </div>
                // </section>
                <div
                  key={index}
                  class="w-full  mx-auto bg-white rounded-3xl shadow-xl overflow-hidden"
                >
                  <div class="max-w-md mx-auto flex-col">
                    <img
                      src={`${ImgUrl}events/${item.photo}`}
                      alt=""
                      className=""
                    />
                    <div class="p-4 sm:p-6 h-max flex-col">
                      <div className="flex-1 mb-auto">
                        <p class="font-bold grow flex-1 text-gray-700 text-[20px] leading-7 mb-1">
                          {item.title}
                        </p>
                      </div>

                      <Link
                        to={`/event/${item.slug}`}
                        class="block font-bold mt-10 w-full hover:text-white px-4 py-3 cursor-pointer uppercase text-center tracking-wider transition-colors duration-300 transform bg-[#cfb66c] rounded-[14px] hover:bg-[#a58f4d] focus:outline-none focus:ring focus:ring-[#a58f4d] focus:ring-opacity-80"
                      >
                        View Event
                      </Link>
                    </div>
                  </div>
                </div>
              ))}{" "}
            </main>
            {/* {!showAll && (
              <div className="flex justify-center items-center mt-10">
                <button onClick={handleButtonClick} className="button">
                  See All Events
                </button>
              </div>
            )} */}
            {showAll && EventData.length <= 2 && (
              <div className="flex justify-center items-center mt-10">
                <h1 className="font-medium md:text-base">
                  There is no more Events.
                </h1>
              </div>
            )}
          </>
        ) : (
          <div className="md:text-xl text-lg mb-1 h-[40vh] pt-16 font-semibold uppercase">
            No Event Listed
          </div>
        )}
      </section>
      {selectedItem && (
        <div
          class={`fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-70 py-10 z-[100000] ${
            openModal ? "block" : "hidden"
          }`}
        >
          <div class="max-h-full relative max-w-4xl overflow-y-auto sm:rounded-2xl mx-5 bg-white">
            {/* <div className="absolute z-[20] top-0 right-0 text-red-700 py-2 px-2 text-xl rounded-full border">
              <AiOutlineClose size={30} className="" />
            </div> */}
            <div class="w-full p-10">
              <h1 class="mb-10 text-3xl font-extrabold text-center">
                {selectedItem.title}
              </h1>
              <div class="m-4 mx-auto">
                {/* <img
                  src={`${ImgUrl}events/${selectedItem.photo}`}
                  alt=""
                  className="max-w-[30rem] mb-10 mx-auto"
                /> */}
                <div class="">
                  {/* <div className="md:col-span-3 col-span-5 text-justify flex items-center">
                    <p class="text-gray-600">{selectedItem.body}</p>
                  </div> */}
                  <section className="py-[120px] max-w-[1140px] mx-auto min-h-[50vh] px-5 xl:px-0">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedItem?.body,
                      }}
                    />
                  </section>
                </div>
                <div className="flex justify-center items-center mt-10">
                  <button
                    class="button rounded-full"
                    onClick={() => setOpenModal(!openModal)}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="my-10 flex justify-between items-center">
        <div
          className={`${currentPage > 1 ? "newButton" : "disabled"}`}
          onClick={prevPage}
        >
          Previous
        </div>
        <div
          className={`${currentPage < totalPages ? "newButton" : "disabled"}`}
          onClick={nextPage}
        >
          Next
        </div>
      </div>
    </>
  );
};

export default EventCards;
