import React from "react";
import { Hero, PublicationsComponent } from "../component/publication";

const Publications = () => {
  return (
    <>
      <main className="teamBg">
        <section className="py-[120px] bg-black  text-white bg-opacity-80">
          <Hero />
        </section>
      </main>
      <section className=" pt-[50px] max-w-[1140px] mx-auto px-5 xl:px-0">
        <PublicationsComponent />
      </section>
    </>
  );
};

export default Publications;
