import React from "react";
import { useForm } from "react-hook-form";
import { XHR } from "../../app/request";
import { toast } from "react-hot-toast";
import { PaystackButton, usePaystackPayment } from "react-paystack";

const config = {
  reference: new Date().getTime().toString(),
  email: "admin@uoalc.com",
  amount: 1000000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
  publicKey: "pk_test_b3e254392ba5d8f8382e46733a0d438990b10969",
};

const ContactUsSection = () => {
  const [subject, setSubject] = React.useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fname: "",
      email: "",
      phone: "",
      message: "",
      location: "",
      subject: "",
      mode: "",
    },
  });

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const initializePayment = usePaystackPayment(config);

  const onSubmit = (data) => {
    initializePayment((reference) => {
      XHR("post", "/contact", {
        ...data,
        msg: reference?.message,
        ref: reference?.reference,
        trans: reference?.trans,
        status: reference?.status,
      })
        .then((res) => {
          console.log(reference);
          console.log(data);
          toast.success(`Submitted Successfully ${data.fname}.`, {
            style: {
              border: "1px solid #cfb66c",
              padding: "16px",
              color: "#cfb66c",
            },
            iconTheme: {
              primary: "#cfb66c",
            },
          });
          console.log(res.data);
          reset({
            fname: "",
            email: "",
            phone: "",
            message: "",
            location: "",
            subject: "",
            mode: "",
          });
        })
        .catch((err) => console.log(err));
    }, onClose);
  };

  React.useEffect(() => {
    XHR("get", "/gpas/titles")
      .then((res) => {
        setSubject(res.data);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <section className="max-w-[1140px] mx-auto">
      <div className="max-w-sm mb-5 flex items-center justify-between space-x-5">
        <h6 className="shrink-0 font-semibold">Contact Us</h6>
        <span className="h-[2px] w-full bg-[#cfb66c]"></span>
      </div>
      <div className="grid md:grid-cols-5 gap-5">
        <div className="md:col-span-2">
          <div className="max-w-sm">
            <h1 className="md:text-4xl text-3xl  font-bold mb-10 sideFont">
              Request a Lawyer
            </h1>
            <p className="font-semibold">
              UOALC reflects both the analog and digital ages whereby you can
              make a choice of consulting us VIRTUALLY or PHYSICALLY and your
              legal concerns will be attended to speedily and efficiently.
            </p>
          </div>
        </div>

        <div className="md:col-span-3 mb-16">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col mt-5 mb-3">
              <label
                htmlFor="name"
                className="text-xs font-semibold mb-2 flex justify-between"
              >
                <span> Full Name</span>
                <span className="text-red-500">{errors?.fname?.message}</span>
              </label>
              <input
                type="text"
                className={`bg-white p-4 border  rounded-lg outline-none ${
                  errors?.fname ? "border-red-500" : "border-gray-300"
                }`}
                {...register("fname", {
                  required: { value: true, message: "This field is required" },
                })}
              />
            </div>
            <div className="grid md:grid-cols-2 gap-x-5">
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-xs font-semibold mb-2 flex justify-between"
                >
                  <span> Email</span>
                  <span className="text-red-500">{errors?.email?.message}</span>
                </label>
                <input
                  type="email"
                  className={`bg-white p-4 border  rounded-lg outline-none ${
                    errors?.email ? "border-red-500" : "border-gray-300"
                  }`}
                  {...register("email", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-xs font-semibold mb-2 flex justify-between"
                >
                  <span> Phone</span>
                  <span className="text-red-500">{errors?.phone?.message}</span>
                </label>
                <input
                  type="phone"
                  className={`bg-white p-4 border  rounded-lg outline-none ${
                    errors?.phone ? "border-red-500" : "border-gray-300"
                  }`}
                  {...register("phone", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
              </div>
            </div>
            <div className="flex flex-col mt-5 mb-3">
              <label
                htmlFor="name"
                className="text-xs font-semibold mb-2 flex justify-between"
              >
                <span> Location</span>
                <span className="text-red-500">
                  {errors?.location?.message}
                </span>
              </label>
              <input
                type="text"
                className={`bg-white p-4 border  rounded-lg outline-none ${
                  errors?.location ? "border-red-500" : "border-gray-300"
                }`}
                {...register("location", {
                  required: { value: true, message: "This field is required" },
                })}
              />
            </div>
            <div className="grid md:grid-cols-2 gap-x-5">
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-xs font-semibold mb-2 flex justify-between"
                >
                  <span> Mode of Consultation</span>
                  <span className="text-red-500">{errors?.mode?.message}</span>
                </label>
                <select
                  id="countries"
                  className={`bg-white border  text-sm rounded-lg outline-none appearance-none block w-full p-4 ${
                    errors?.mode ? "border-red-500" : "border-gray-300"
                  }`}
                  {...register("mode", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                >
                  <option selected></option>
                  <option value="Physical">Physical Consultation</option>
                  <option value="Virtual">Virtual Consultation</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-xs font-semibold mb-2 flex justify-between"
                >
                  <span> Subject</span>
                  <span className="text-red-500">
                    {errors?.subject?.message}
                  </span>
                </label>
                <select
                  id="countries"
                  className={`bg-white border  text-sm rounded-lg outline-none appearance-none block w-full p-4 ${
                    errors?.subject ? "border-red-500" : "border-gray-300"
                  }`}
                  {...register("subject", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                >
                  <option selected></option>
                  {subject.map((item, i) => (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col mt-5">
              <label
                htmlFor="name"
                className="text-xs font-semibold mb-2 flex justify-between"
              >
                <span> Message</span>
                <span className="text-red-500">{errors?.message?.message}</span>
              </label>
              <textarea
                type="text"
                cols={5}
                className={`bg-white p-4 border resize-none h-52  rounded-lg outline-none ${
                  errors?.message ? "border-red-500" : "border-gray-300"
                }`}
                placeholder="A brief summary of the purpose of your consultation."
                {...register("message", {
                  required: { value: true, message: "This field is required" },
                })}
              ></textarea>
            </div>
            <div>
              <button type="submit" className="button my-5">
                Book
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* <div className="grid md:grid-cols-5 gap-5">
        <div className="md:col-span-2">
          <div className="max-w-sm">
            <h1 className="md:text-4xl text-3xl font-bold mb-10 sideFont">
              Book a Consultation
            </h1>
          </div>
        </div>
        <div className="md:col-span-3 mb-10">
          <form action="">
            <div className="grid grid-cols-2 gap-x-5">
              <div className="flex flex-col">
                <label htmlFor="name" className="text-xs font-semibold mb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  className={`bg-white p-4 border  rounded-lg outline-none ${errors?.fname ? 'border-red-500' : 'border-gray-300'}`}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="name" className="text-xs font-semibold mb-2">
                  Email
                </label>
                <input
                  type="text"
                  className={`bg-white p-4 border  rounded-lg outline-none ${errors?.fname ? 'border-red-500' : 'border-gray-300'}`}
                />
              </div>
            </div>
            <div className="flex flex-col mt-5">
              <label htmlFor="name" className="text-xs font-semibold mb-2">
                Message
              </label>
              <textarea
                type="text"
                className="bg-white p-4 border h-52 resize-none border-gray-300 rounded-lg outline-none"
              ></textarea>
            </div>
            <div>
              <button className="button my-5">Book a Consultation</button>
            </div>
          </form>
        </div>
      </div> */}
    </section>
  );
};

export default ContactUsSection;
