import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { XHR } from "../../app/request";

const Hero = ({ title, subTitle }) => {
  const [counters, setCounters] = React.useState({});
  const fetchCounter = () => {
    XHR("get", "/counters")
      .then((res) => {
        setCounters(res.data[0]);
        // console.log(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    fetchCounter();
  }, []);
  return (
    <section className="">
      <div className="grid lg:grid-cols-  gap-5 py-[120px] max-w-[1140px] mx-auto px-5 xl:px-0">
        <div className="col-span-3 h-[50vh] mt-16">
          <div className="max-w-[30rem]">
            <h1 className="lg:text-[3.5rem] text-[2rem] header font-semibold mb-5">
              {/* Why gamble <br /> your{" "} */}
              {title}
              {/* <span className="uppercase font-bold sideFont">success?</span> */}
            </h1>
          </div>

          <div className="max-w-sm mb-10 font-medium">
            <p className="mb-5">{subTitle}</p>
          </div>
          <div className=" flex space-x-5">
            <Link to="/contact" className="button text-sm md:text-base">
              Request a Lawyer
            </Link>
            <Link to="/contact" className="button text-sm md:text-base">
              Book a consultation
            </Link>
          </div>
        </div>
        <div className="hidden lg:col-span-2"></div>
      </div>
      <div className="bg-blue-900 py-5 bg-opacity-30">
        <div className="flex justify-around text-center">
          <div className="flex items-center space-x-5">
            <h1 className="lg:text-4xl text-2xl  sideFont">
              <CountUp
                start={0}
                end={counters?.client}
                duration={3}
                delay={1}
              />
              +
            </h1>
            <p className="max-w-[5rem] text-sm text-start font-semibold">
              Satisfied Clients
            </p>
          </div>
          <div className="flex items-center space-x-5">
            <h1 className="lg:text-4xl text-2xl sideFont">
              <CountUp start={0} end={counters?.case} duration={3} delay={1} />+
            </h1>
            <p className="max-w-[5rem] text-sm text-start font-semibold">
              Cases handled
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
