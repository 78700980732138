import React from "react";

const Hero = () => {
  return (
    <section className="max-w-[1140px] mx-auto flex justify-center items-baseline">
      <div className=" grow">
        <div className="max-w-2xl mb-5 mt-10 mx-auto flex items-center justify-between space-x-10">
          <h6 className="shrink-0 font-semibold text-4xl ">Our Team</h6>
          <span className="h-[3px] w-full bg-[#cfb66c]"></span>
        </div>
        <div className=" flex justify-center  mt-5">
          <div className="max-w-2xl text-center">
            <h1 className="text-[3.5rem] font-semibold tracking-wider mb-5 sideFont">
              Meet Our Team
            </h1>
            <p className="tracking-wider leading-7 font-semibold">
              Uche Ojembe and Associates Legal Consult (UOALC) is a firm of
              seasoned legal <br /> practitioners whose collaborations in various{" "}
              <br />
              disciplines produce innovative, cutting-edge legal solutions for
              clients.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
