import React, { useEffect } from "react";
import { useState } from "react";
import { XHR, ImgUrl } from "../../app/request";
import { truncateText } from "../../lib/utils";

const Sectors = () => {
  const [SofData, setSofData] = React.useState([]);

  const fetchData = () => {
    XHR("get", "/sofs")
      .then((res) => {
        setSofData(res.data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const SOF = [
    {
      heading: "Real estate & construction sector",
      desc: "At UOALC, our real estate legal team provides guidance on all kinds of real estate transactions for owners, shareholders, contractors, developers, engineers, private and institutional investors, and real estate and construction developers, thereby assisting them in successfully achieving their corporate objectives while also ensuring the protection of their rights and interests because the Nigerian real estate sector is a complex and challenging field to navigate. Our expert team of real estate lawyers provides thorough and in-depth guidance on drafting and executing real estate agreements and contracts, title perfection, representing individuals and business organizations in the purchase and sale of real estate, preparing lease agreements for companies, and ensuring that client's real estate activities comply with the applicable laws. Our diverse approach to real estate transactions and matters makes us reliable advisors for domestic and foreign contractors and engineers, property investment firms, financial organizations, and private and public investors. The legal practice of real estate law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our real estate lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Aviation & maritime sector",
      desc: "Our aviation and maritime advisory team offer guidance on licensing and regulation of airlines and vessels, passenger rights, carrier rights and obligations, and suitable compensation for aviation and marine accidents. As a prominent law firm, we offer pre-dispute advice regarding legal liability and compliance and represent leading entities and individuals in the aviation and maritime industry. Our qualified lawyers in this practice sector also possess the specific, legislative, and legal knowledge necessary for efficiently processing and settling disputes in aviation and maritime matters. The legal practice of aviation & maritime law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our aviation and maritime lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Health care sector",
      desc: "Our healthcare legal team offers advice on - legal regulation of drugs and pharmacies in compliance with the appropriate agencies [e.g., NAFDAC], regulation of healthcare centres, hospitals, and pharmaceutical projects, distribution of pharmaceutical drugs, products, and devices, managing product liability, protection of manufacturer and consumer rights, processing health agencies contracts, guidance on financial investments and health insurance scheme. We also represent our clients in dispute resolution and litigation matters related to issues arising from this sector.",
    },
    {
      heading: "Manufacturing & industry sector",
      desc: "Our manufacturing and industry practice group advises some of the top manufacturing and industrial firms in Nigeria and internationally on important business matters such as complicated corporate, commercial, and business transactions, intellectual property, and dispute resolution emanating from such transactions in the manufacturing and industrial sector.",
    },
    {
      heading: "Infrastructure sector",
      desc: "We have extensive experience in advising clients on all aspects of infrastructure, including funding, financial investment, structure, electricity, building, and construction. We have assisted clients in preparing bid contracts, private-public partnership agreements, and product development agreements. Our infrastructure legal practice group has extensive experience advising clients on various construction projects developments such as railway, aviation, maritime infrastructure, shopping malls, estate housing, hospitals, schools, hostels, and residential and industrial projects. We also offer guidance on social project developments such as installations of electric power plants, telecommunication masts and cables, and water projects.",
    },
    {
      heading: "Insurance sector",
      desc: "Our insurance practice group advises several kinds of insurers on insurance, reinsurance, investment, capital, and liquidity in the Nigerian insurance sector. Due to our results-driven and client-focused approach, we&#39;ve earned an unmatched reputation as legal advisors for leading companies in Nigeria&#39;s insurance industry. Our legal insurance experts advise on the establishment of new businesses, private and institutional mergers and acquisitions, corporate restructurings, and debt and equity markets. We also represent our clients in dispute resolution and litigation related to insurance matters. The legal practice of insurance law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our insurance lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Maritime sector",
      desc: "Our maritime law team provides legal advice on marine trade, commercial export and import transactions, seaborne cargo/freight shipping, cabotage, and other applicable regulations and restrictions domestically and globally. This legal unit also specializes in maritime litigation and dispute resolution concerning vessel arrest and vessel release on behalf of vessel owners, lenders, and charterers. The legal practice of maritime law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our maritime lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Mining sector",
      desc: "UOALC’s mining group is knowledgeable in mining policies, regulations, and laws. We offer legal services to mining companies and enterprises, such as- obtaining permits and licenses for the development, exploration, extraction, and processing of minerals, handling mining transactions and investments in the mining industry, tax issues relating to the mining industry, mining project funding options, documentation, and record-keeping of mining project records, regulatory compliance in Nigeria’s mining industry and other operational matters. Our team also handles issues relating to the mining and production of additional minerals and metals.",
    },
    {
      heading: "Oil & gas sector",
      desc: "Our Oil and Gas legal team has vast experience in gas development projects and representing clients in dispute resolution regarding this sector. Our services include - risk management and evaluation, drafting and preparing the essential documentation for the task at hand, drafting gas transaction agreements, managing transactional agreements and contracts, and maintaining financial records in oil &amp; gas projects. This practice group also manages the legal aspect of regulatory inspections, upstream drilling and extraction, oil, and gas processing and conveyance, marketing, and consumption. We pride ourselves in our professional group of talented oil & gas lawyers. The legal practice of oil & gas law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our oil & gas lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Power & renewable energy sector",
      desc: "UOALC’s power &amp; renewable energy practice group offers practical and beneficial legal counsel to companies, shareholders, and creditors in every aspect of power and renewable energy challenges, including oil and gas law. Our seasoned lawyers have extensive experience in advising private and governmental corporations in the Nigerian power sector starting from the prospect to the fiscal closing and execution of the most challenging power and upstream energy projects. Our expertise in the power and energy resources field extends to – guidance on foreign investments, drafting, negotiation, and processing of commercial contracts for transactions under this sector, and ensuring our clients maintain regulatory compliance with the appropriate agencies. We also offer legal representation and assistance on various energy-related challenges, including some of the most complicated and cutting-edge deals involving oil, gas, LNG, and petroleum-based products. Our understanding of legal and regulatory problems affecting Nigeria's power and energy sector benefits our clients.",
    },
    {
      heading: "Consumer products & retail",
      desc: "Our legal team assists OEMs and manufacturing companies, suppliers and distributors, vendors and buyers, wholesalers and retailers, and importers and exporters in manoeuvring this sector's commercial and regulatory framework. Our specialists in this field give thorough, beneficial, and tailored guidance in the licensing and protection of intellectual property, applications, registrations and permits, imports, exports, and distribution of consumer products, regulatory inspections, the safety of consumer goods, processing of privacy policy and data security for manufacturing companies [OEMs], and dispute resolution or litigation in commercial transactions.",
    },
    {
      heading: "Technology, innovation & fintech",
      desc: "UOALC’s legal team has advised some of the top technology and fintech companies operating in Nigeria by assisting them in adequately managing and utilizing the national economic sector. We also collaborate with many fintech enterprises, guiding them in handling this sector's West African regulatory framework. Our clients trust and depend on us to navigate the complexities of this diverse industry sector. We provide value-added advice and support in completing complex business transactions, ensuring regulatory compliance, structuring business organizations, and arranging multilevel financing. The legal practice of fintech law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our fintech lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Telecommunications",
      desc: "UOALC’s telecommunication advisory team offers advice to clients on telecommunication regulations and laws, statutory applications, prescribed license acquisition, and various marketplace strategies. We assist with restructuring, alliances, cooperation partnerships, purchase contracts, and deals. Our legal services in respect to this field extend to the application, registration, and processing of telecommunications license to foreign corporations who wish to operate in Nigeria, drafting licensing regulations for the provision of telecommunication services and operation of communication systems, and providing a wide range of options for a commercial dispute in business transactions if the need arises.",
    },
    {
      heading: "Media & entertainment",
      desc: "Our media and entertainment advisory team assists clients in every area of this industry, which is evolving, growing, and generating a significant impact. As for regulatory and legal matters, we offer legal advice to major stakeholders in the media and entertainment industry. Among the many services we offer are - guidance on consumer rights, cyber security and data protection, competition issues, regulatory and compliance issues, negotiating business arrangements, drafting and reviewing business contracts and transactions, providing various financing tactics, privatization, and handling the application and approval of regulatory licence or permits. Our seasoned lawyers have an excellent track record. They are aware of the difficulties clients face in this industry, which is why we develop creative and affordable alternatives to our client's business needs. We have negotiated complex transactions on behalf of firms and investors in this industry, including notable media and entertainment firms, celebrities, shareholders, and many others in Nigeria and internationally. The legal practice of media & entertainment law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our media & entertainment lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Probate and Estate Administration",
      desc: "Our legal experts in the probate and estate administration practice unit assist executors and administrators with estate representation and management. We represent heirs, beneficiaries, trustees, administrators, and executors. Our professional practice group has handled matters involving a dispute over the validity of a will or trust, incapacity, undue influence, allegations of breach of fiduciary duty and misconduct, fraud, and inadequate estate administration. Regarding probate administration, UOALC is currently appointed as trustees and executors of our client's estates. Our probate and estate administration services also extend to – the administration of probate and trusts, filing the register of wills, asset acquisition, asset transfer, settling claims from creditors, assisting heirs and beneficiaries, and life insurance claims. The legal practice of probate and estate administration law is different from other practice areas. It takes a specific level of knowledge and experience to avoid making common legal mistakes. Hire our probate lawyers with years of experience to bolster your legal efforts and understand your rights.",
    },
    {
      heading: "Startups & msme",
      desc: "UOALC provides legal services to Startups and MSMEs. By combining expertise and skill with an in-depth understanding of the regulatory framework, we manage and handle many startups and prevent them from making early mistakes by offering unique and adequate advice on establishment, incorporation, investments, business transactions, and agreements. Our experts guide them through the various stages of their development by offering the appropriate legal and regulatory remedies concerning incorporation, structuring, regulatory compliance, and litigation/dispute resolution. Our Startup practice group handles business establishment and structure, pre- incorporation and post-incorporation matters, licences, and permits, identifying early business risks, funding, and investment consulting, taxation, protection of IP [trademarks, patents & copyrights], partnerships and shareholders agreements, labour and employment matters, drafting and review of contractual agreements, and handling pre- incorporation contracts.",
    },
  ];

  const [expandedPosts, setExpandedPosts] = useState([]);

  const handleToggle = (postId) => {
    if (expandedPosts.includes(postId)) {
      setExpandedPosts(expandedPosts.filter((id) => id !== postId));
    } else {
      setExpandedPosts([...expandedPosts, postId]);
    }
  };
  return (
    <section>
      <div className="max-w-sm mb-5 flex items-center justify-between space-x-5">
        <h6 className="shrink-0 font-semibold">Expertise</h6>
        <span className="h-[2px] w-full bg-[#cfb66c]"></span>
      </div>
      <div className="max-w-sm">
        <h1 className="md:text-4xl text-3xl font-bold mb-10 sideFont">
          Sectors of Focus
        </h1>
      </div>
      <main className="grid lg:grid-cols-3 grid-cols-2 gap-5">
        {SofData.map((item, index) => (
          <div className="p-4 shadow-md border" key={index}>
            <div>
              <img
                src={`${ImgUrl}sofs/${item.photo}`}
                alt=""
                className="w-[320px]  mb-3 mx-auto"
              />
            </div>
            <h1 className="md:text-xl text-sm mb-2 font-semibold uppercase max-w-[15rem]">
              {item.title}
            </h1>
            <p className="font-medium mb-10 text-[10px] md:text-[13px]">
              {expandedPosts.includes(index)
                ? item.body
                : truncateText(item.body, 150)}{" "}
              <span onClick={() => handleToggle(index)}>
                {expandedPosts.includes(index) ? (
                  <span className="text-[#cfb66c] cursor-pointer font-semibold">
                    {" "}
                    hide{" "}
                  </span>
                ) : (
                  <span className="text-[#cfb66c] cursor-pointer font-semibold">
                    show more
                  </span>
                )}
              </span>
            </p>
            {/* <p className="font-medium mb-10 text-[10px] md:text-[13px]">
              <div dangerouslySetInnerHTML={{ __html: item.body }} />
            </p> */}
          </div>
        ))}
      </main>
    </section>
  );
};

export default Sectors;
