import { FiMenu } from "react-icons/fi";
import {
  AiOutlineClose,
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineSearch,
} from "react-icons/ai";
import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const Nav = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { pathname } = useLocation();

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const currentPath = () => {
    if (pathname === "/") return "Home";
    if (pathname === "/about") return "About";
    if (pathname === "/contact") return "Contact";
    if (pathname === "/expertise") return "Expertise";
    if (pathname === "/team") return "Team";
    if (pathname === "/resources") return "Resources";
  };

  const [searchBox, setSearchBox] = React.useState(false);

  React.useEffect(() => {
    const script = document.createElement("script");
    document.head.append(script);
    script.src = "https://cse.google.com/cse.js?cx=64236bad72b024146";
  }, []);
  // Dropdown Logic
  const [resourceDropDown, setResourceDropDown] = React.useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setResourceDropDown(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    setSearchBox(false);
    setResourceDropDown(false);
    setOpenDropdown(false);
  }, [pathname]);

  const [openDropdown, setOpenDropdown] = React.useState(false);

  return (
    <>
      <nav className="fixed w-full top-0 z-[10]">
        <section className="shadow-2xl bg-black text-white bg-opacity-80">
          <div className="flex items-center h-24 justify-between max-w-[1140px] mx-auto px-5 xl:px-0">
            <Link to="/" className="flex items-center">
              <img src={logo} alt="" className="w-40" />
            </Link>
            <nav className="flex space-x-5 items-center font-semibold lg:hidden">
              {/* <p className="text-[#cfb66c]">{currentPath()}</p> */}
              <p
                onClick={toggleNav}
                className=" tracking-tight block cursor-pointer text-3xl hover:text-[#cfb66c]"
              >
                <FiMenu />
              </p>
            </nav>
            <nav className="hidden lg:inline-block">
              <ul className="flex flex-col p-4 mt-4 border rounded-lg lg:flex-row lg:space-x-5 lg:mt-0  lg:border-0 text-xl  ">
                <li>
                  <Link
                    to="/"
                    className={`${
                      pathname === "/" && "text-[#cfb66c] font-bold"
                    }  py-2 pl-3 pr-4 lg:p-0 cursor-pointer duration-300 transition-all ease-in-out hover:text-[#cfb66c] flex items-center space-x-2`}
                    aria-current="page"
                  >
                    <span> Home</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className={`${
                      pathname.toLowerCase().includes("about") &&
                      "text-[#cfb66c] font-bold"
                    }  flex items-center space-x-2 py-2 pl-3 pr-4 lg:p-0 cursor-pointer duration-300 transition-all ease-in-out hover:text-[#cfb66c]`}
                    aria-current="page"
                  >
                    <span>About Us</span>
                  </Link>
                </li>

                <li>
                  <Link
                    to="/team"
                    className={`${
                      pathname.toLowerCase().includes("team") &&
                      "text-[#cfb66c] font-bold"
                    }  flex items-center space-x-2 py-2 pl-3 pr-4 lg:p-0 cursor-pointer duration-300 transition-all ease-in-out hover:text-[#cfb66c]`}
                    aria-current="page"
                  >
                    <span>Our Team</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/expertise"
                    className={`${
                      pathname === "/expertise" && "text-[#cfb66c] font-bold"
                    }  flex items-center space-x-2 py-2 pl-3 pr-4 lg:p-0 cursor-pointer duration-300 transition-all ease-in-out hover:text-[#cfb66c]`}
                  >
                    <span>Expertise</span>
                  </Link>
                </li>
                <li className="group relative" ref={ref}>
                  <div
                    onClick={() => setResourceDropDown(!resourceDropDown)}
                    className={`${
                      pathname.toLowerCase().includes("resources") &&
                      "text-[#cfb66c] font-bold"
                    }  flex items-center  py-2 pl-3 space-x-1 pr-4 lg:p-0 cursor-pointer duration-300 transition-all ease-in-out group-hover:text-[#cfb66c] ${
                      resourceDropDown && "text-[#cfb66c]"
                    }`}
                  >
                    <span>Resources</span>{" "}
                    <span>
                      {resourceDropDown ? (
                        <BsChevronDown className="font-bold mt-2" />
                      ) : (
                        <BsChevronUp
                          className={`group-hover:rotate-180 duration-300 transition ease-linear font-bold mt-2`}
                        />
                      )}
                    </span>
                  </div>
                  <div
                    class={`absolute end-0 z-10 mt-5 w-56 -right-16 group-hover:block transition-all duration-200 rounded-md border border-gray-100 bg-black shadow-lg ${
                      !resourceDropDown && "hidden"
                    }`}
                    role="menu"
                  >
                    <div class="p-2">
                      <Link
                        to="/resources/publication"
                        class="block rounded-lg px-4 py-2 font-semibold text-gray-200 hover:text-[#cfb66c]"
                        role="menuitem"
                      >
                        Publications
                      </Link>

                      <Link
                        to="/resources/newsletter"
                        class="block rounded-lg px-4 py-2 font-semibold text-gray-200 hover:text-[#cfb66c]"
                        role="menuitem"
                      >
                        NewsLetter
                      </Link>
                    </div>
                  </div>
                </li>
                <li>
                  <Link
                    to="/event"
                    className={`${
                      pathname.toLowerCase().includes("event") &&
                      "text-[#cfb66c] font-bold"
                    }  flex items-center space-x-2 py-2 pl-3 pr-4 lg:p-0 cursor-pointer duration-300 transition-all ease-in-out hover:text-[#cfb66c]`}
                  >
                    <span>Events</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className={`${
                      pathname.toLowerCase().includes("contact") &&
                      "text-[#cfb66c] font-bold"
                    }  flex items-center space-x-2 py-2 pl-3 pr-4 lg:p-0 cursor-pointer duration-300 transition-all ease-in-out hover:text-[#cfb66c]`}
                  >
                    <span>Contact Us</span>
                  </Link>
                </li>
              </ul>
            </nav>
            <button
              onClick={() => setSearchBox(!searchBox)}
              className="order-first lg:order-last search-menu flex justify-center items-center  font-medium text-gray-100  whitespace-nowrap  hover:text-white
    rounded-xl shadow-lg hover:shadow-xl transform hover:scale-105 transition duration-500 focus:outline-none"
            >
              <AiOutlineSearch size={35} />
            </button>
          </div>
        </section>
        <div
          className={`${
            isOpen ? "fixed left-0 top-0 w-full h-screen backdrop-blur-sm" : ""
          } `}
        >
          <div
            className={
              isOpen
                ? "fixed right-0 top-0 w-[75%] sm:w-[40%] md:w-[40%] lg:w-[25%] h-screen bg-[#ffffff] p-10 ease-in duration-300"
                : "fixed -right-[100%] top-0 p-10 ease-in h-screen duration-500"
            }
          >
            <div>
              <div className="flex items-end justify-end">
                <div
                  onClick={toggleNav}
                  className="text-3xl cursor-pointer hover:text-[#cfb66c]"
                >
                  <AiOutlineClose />
                </div>
              </div>

              <div className="py-4 mt-5">
                <ul className="uppercase flex flex-col font-semibold justify-end text-3xl text-end">
                  <Link to="/">
                    <li
                      onClick={() => setIsOpen(!isOpen)}
                      className="py-4 hover:text-[#cfb66c]"
                    >
                      Home
                    </li>
                  </Link>
                  <Link to="/about">
                    <li
                      onClick={() => setIsOpen(!isOpen)}
                      className="py-4 hover:text-[#cfb66c]"
                    >
                      About
                    </li>
                  </Link>
                  <Link to="/team">
                    <li
                      onClick={() => setIsOpen(!isOpen)}
                      className="py-4 hover:text-[#cfb66c]"
                    >
                      Team
                    </li>
                  </Link>
                  <Link to="/expertise">
                    <li
                      onClick={() => setIsOpen(!isOpen)}
                      className="py-4 hover:text-[#cfb66c]"
                    >
                      Expertise
                    </li>
                  </Link>
                  <div>
                    <li
                      onClick={() => setOpenDropdown(!openDropdown)}
                      className="py-4 flex justify-between items-center hover:text-[#cfb66c] cursor-pointer"
                    >
                      <span>
                        {openDropdown ? <AiOutlineMinus /> : <AiOutlinePlus />}
                      </span>
                      <span>Resources</span>
                    </li>
                    <div
                      className={`py-4 my-2 ${
                        openDropdown ? "flex" : "hidden"
                      }`}
                    >
                      <ul className="uppercase flex flex-col font-semibold justify-end text-3xl text-end">
                        <Link
                          to="/resources/publication"
                          class="block rounded-lg px-4 py-2 font-semibold text-gray-400 hover:text-[#cfb66c]"
                          role="menuitem"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          Publications
                        </Link>

                        <Link
                          to="/resources/newsletter"
                          class="block rounded-lg px-4 py-2 font-semibold text-gray-400 hover:text-[#cfb66c]"
                          role="menuitem"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          NewsLetter
                        </Link>
                      </ul>
                    </div>
                  </div>
                  <Link to="/event">
                    <li
                      onClick={() => setIsOpen(!isOpen)}
                      className="py-4 hover:text-[#cfb66c]"
                    >
                      Event
                    </li>
                  </Link>
                  <Link to="/contact">
                    <li
                      onClick={() => setIsOpen(!isOpen)}
                      className="py-4 hover:text-[#cfb66c]"
                    >
                      Contact
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section
          className={`bg-black/80 top-0 w-screen h-screen ${
            searchBox ? "block" : "hidden"
          }`}
        >
          <div className="max-w-xl z-20 mx-auto">
            <div class="gcse-search"></div>
          </div>
          <div
            className="search-form flex items-center space-x-4 px-6 mx-auto max-w-screen-xl md:px-12 lg:px-16 xl:px-24 
  transform duration-500 transition-all"
          >
            {/* <div class="flex bg-gray-200 p-2 w-full space-x-2 rounded-lg items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 opacity-75 ml-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              <input
                className={`w-full py-2 gcse-search px-3 bg-gray-50 outline-none border-transparent focus:border-transparent focus:ring-0 rounded-lg text-sm sm:text-base ${
                  searchBox ? "block z-10" : "hidden"
                }`}
                type="text"
                autofocus
                placeholder="Search keyword..."
                // onBlur={() => setSearchBox(!searchBox)}
              />
              <button className="buttonSearch">Submit</button>
            </div> */}
          </div>
        </section>
      </nav>
    </>
  );
};

export default Nav;
