import React, { useEffect, useState } from "react";
import { ImgUrl, XHR } from "../../app/request";
import { ScrollToTop, truncateText } from "../../lib/utils";
import { Link } from "react-router-dom";

const Newsletters = () => {
  const [publicationData, setPublicationData] = useState([]);
  const [newsletterData, setNewsletterData] = useState([]);

  // MODAL FUNCTIONALITY
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setOpenModal(true);
  };
  // END MODAL FUNCTIONALITY
  // ==================================================================================================

  // THE SHOW MORE NEWSLETTER FUNCTIONALITY
  const [showAllNewsletter, setShowAllNewsletter] = React.useState(false);

  // Function to handle the button click
  const handleNewsletterButtonClick = () => {
    setShowAllNewsletter(true);
  };

  // Slice the array based on the showAll state
  const displayNewsletter = showAllNewsletter
    ? newsletterData
    : newsletterData.slice(0, 2);
  // END SHOW MORE NEWSLETTER FUNCTIONALITY

  // ==================================================================================================

  // THE SHOW MORE PUBLICATION FUNCTIONALITY
  const [showAllPublication, setShowAllPublication] = React.useState(false);

  // Function to handle the button click
  const handlePublicationButtonClick = () => {
    setShowAllPublication(true);
  };

  // Slice the array based on the showAll state
  // const displayPublication = showAllPublication
  //   ? publicationData
  //   : publicationData.slice(0, 2);
  // END SHOW MORE PUBLICATION FUNCTIONALITY

  // ==================================================================================================
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const fetchNewsletterData = (page) => {
    XHR("get", `/newsletters?page=${page}`)
      .then((res) => {
        setNewsletterData(res.data.data);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  useEffect(() => {
    fetchNewsletterData(currentPage);
  }, [currentPage]);

  const Empty = [];
  ScrollToTop();
  return (
    <>
      <section className="my-10">
        <div className="max-w-sm mb-5 flex items-center justify-between space-x-5">
          <h6 className="shrink-0 font-semibold">Resources</h6>
          <span className="h-[2px] w-full bg-[#cfb66c]"></span>
        </div>
        <div className="max-w-sm">
          <h1 className="text-4xl font-bold mb-10 sideFont">Newsletters</h1>
        </div>
        {displayNewsletter.length > 0 ? (
          <>
            <main className="grid md:grid-cols-3 gap-5">
              {displayNewsletter.map((item, index) => (
                // <div className="p-4 shadow-md border" key={index}>
                //   <div className="flex justify-center items-center">
                //     <img
                //       src={`${ImgUrl}newsletters/${item.photo}`}
                //       alt=""
                //       className="w-[20rem] m-0"
                //     />
                //   </div>
                //   <h1 className="md:text-xl text-lg mb-1 font-semibold uppercase">
                //     {item.title}
                //   </h1>
                //   <p className="font-medium mb-5 text-sm md:text-base">
                //     {truncateText(item.body, 300)}
                //   </p>
                //   <p
                //     onClick={() => handleItemClick(item)}
                //     className="text-[#cfb66c] cursor-pointer"
                //   >
                //     Read
                //   </p>
                // </div>

                <div class="w-full max-w-md  mx-auto bg-white rounded-3xl shadow-xl overflow-hidden">
                  <div class="max-w-md mx-auto">
                    <img
                      src={`${ImgUrl}newsletters/${item.photo}`}
                      alt=""
                      className=""
                    />
                    <div class="p-4 sm:p-6">
                      <p class="font-bold text-gray-700 text-[22px] leading-7 mb-1">
                        {item.title}
                      </p>

                      <Link
                        to={`/resources/newsletter/${item.slug}`}
                        class="block mt-10 w-full px-4 py-3 cursor-pointer hover:text-white font-semibold tracking-wide text-center capitalize transition-colors duration-300 transform bg-[#cfb66c] rounded-[14px] hover:bg-[#a58f4d] focus:outline-none focus:ring focus:ring-[#a58f4d]focus:ring-opacity-80"
                      >
                        View Newsletter
                      </Link>
                    </div>
                  </div>
                </div>
              ))}{" "}
            </main>
            {/* {!showAllNewsletter && (
              <div className="flex justify-center items-center mt-10">
                <button
                  onClick={handleNewsletterButtonClick}
                  className="button"
                >
                  See All Newsletter
                </button>
              </div>
            )} */}
            {/* {showAllNewsletter && newsletterData.length <= 2 && (
              <div className="flex justify-center items-center mt-10">
                <h1 className="font-medium md:text-base">
                  There is no more Newsletter.
                </h1>
              </div>
            )} */}
          </>
        ) : (
          <div className="md:text-xl text-lg mb-1 h-[20vh] pt-16 font-semibold uppercase">
            No Newsletter Listed
          </div>
        )}
      </section>
      {selectedItem && (
        <div
          className={`fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-70 py-10 z-[100000] ${
            openModal ? "block" : "hidden"
          }`}
        >
          <div class="max-h-full max-w-5xl overflow-y-auto mx-5 bg-white">
            <div class="w-full p-10">
              <h1 class="mb-4 text-3xl font-extrabold text-center">
                {selectedItem.title}
              </h1>
              <div class="m-4 mx-auto">
                <div class="">
                  <div className="flex items-center">
                    <div
                      dangerouslySetInnerHTML={{ __html: selectedItem?.body }}
                    />
                  </div>
                </div>
                <div className="flex justify-center items-center mt-10">
                  <button
                    class="button rounded-full"
                    onClick={() => setOpenModal(!openModal)}
                  >
                    cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="my-16 flex justify-between items-center">
        <div
          className={`${currentPage > 1 ? "newButton" : "disabled"}`}
          onClick={prevPage}
        >
          Previous
        </div>
        <div
          className={`${currentPage < totalPages ? "newButton" : "disabled"}`}
          onClick={nextPage}
        >
          Next
        </div>
      </div>
    </>
  );
};

export default Newsletters;
