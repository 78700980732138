import React from "react";
import { ImgUrl, XHR } from "../../app/request";
import { useEffect } from "react";
import { truncateText } from "../../lib/utils";

const Cards = () => {
  const [showMore, setShowMore] = React.useState(false);
  const [team, setTeam] = React.useState([]);

  const ucheText = ` Uchenna Ojembe., Esq. LL.M, is the Principal Partner and Counsel
  of UOALC and is a leading Corporate Governance and Financial
  services sector, real estate and conveyancing practitioner in
  Nigeria. He heads the team that advises commercial banks that
  engage our firm in Nigeria. Uchenna also practices with the firm’s
  corporate and commercial law and capital markets departments and
  has overseen the listing of notes programs on the Nigerian
  Securities Exchange by a telecommunications company, a leading
  financial institution, a sugar manufacturer and a regional
  development bank. Uchenna also possesses expertise in disputes
  work and has obtained favourable results for clients in the
  Magistrate/District Court, High Court of Justice, Court of Appeal
  and Supreme Court in Nigeria in high level disputes. In real estate, Uchenna is
  currently advising the principals on the development of a 1.8 billion luxury estate in Abuja, Nigeria, 
  including providing legal counsel on a range of land
  acquisition, development and title regularization matters. He also specializes in debt recovery and
  has recovered over 1.5 billion for various clients on different levels of expertise. Uchenna holds a
  Bachelor of Laws degree of University of Abuja, a Master of Laws (LL.M) in Corporate Governance
  and Finance Law of the University of Nasarawa in Nigeria. He is a member of the Nigerian Bar
  Association.`;

  const fetchTeam = () => {
    XHR("get", "/teams")
      .then((res) => {
        setTeam(res.data);
        // console.log(res.data)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchTeam();
  }, []);

  function isEven(number) {
    return number % 2 === 0;
  }

  const [expandedPosts, setExpandedPosts] = React.useState([]);

  const handleToggle = (postId) => {
    if (expandedPosts.includes(postId)) {
      setExpandedPosts(expandedPosts.filter((id) => id !== postId));
    } else {
      setExpandedPosts([...expandedPosts, postId]);
    }
  };

  return (
    <section>
      {/* Loop */}
      {team.map((member, index) => {
        const { bio, photo, name } = member;
        return (
          <div className="grid md:grid-cols-2 gap-5 py-[50px]">
            <div
              className={`flex flex-col justify-center items-center md:items-start ${
                isEven(index) || "order-first md:order-last"
              }`}
            >
              <h1 className="uppercase mb-3 lg:text-4xl text-2xl sideFont tracking-wide text-start font-semibold">
                {name}
              </h1>
              <div className="sm md:text-base">
                <p className="max-w-md font-medium">
                  {/* <div dangerouslySetInnerHTML={{ __html: bio }} /> */}
                  {expandedPosts.includes(index) ? bio : truncateText(bio, 650)}
                  {bio.length > 650 && (
                    <span onClick={() => handleToggle(index)}>
                      {expandedPosts.includes(index) ? (
                        <span className="text-[#cfb66c] cursor-pointer font-semibold">
                          {" "}
                          hide{" "}
                        </span>
                      ) : (
                        <span className="text-[#cfb66c] cursor-pointer font-semibold">
                          show more
                        </span>
                      )}
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <img src={`${ImgUrl}teams/${photo}`} alt="" className="w-96" />
            </div>
          </div>
        );
      })}
      {/* Loop end*/}
    </section>
  );
};

export default Cards;
