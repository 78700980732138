import React from "react";
import { TbPhoneCall } from "react-icons/tb";
import { MdLocationOn } from "react-icons/md";
import { BsFillEnvelopeFill } from "react-icons/bs";

const ContactUs = () => {
  return (
    <section className="max-w-[1140px] mx-auto">
      <div className="grid md:grid-cols-5 gap-5">
        <div className="md:col-span-2">
          <div className="max-w-sm mb-5 flex items-center justify-between space-x-5">
            <h6 className="shrink-0 font-semibold">Contact Us</h6>
            <span className="h-[2px] w-full bg-[#cfb66c]"></span>
          </div>

          <div className="max-w-sm">
            <h1 className="text-4xl font-bold mb-10 sideFont">
              In need of a lawyer as soon as possible?
            </h1>
          </div>
        </div>
        <div className="md:col-span-3">
          <div className="">
            <p className="font-medium text-lg ">
              Our legal team of experts are available. <br /> Contact us Today.
            </p>
          </div>
          <div className="my-7">
            <TbPhoneCall size={43} className="mb-3 text-[#cfb66c]" />
            <div className="max-w-xs">
              <a
                className="font-medium text-lg max-w-sm"
                href="tel:+2348097091229"
              >
                +234 809 7091 229
              </a>
            </div>
          </div>
          <div className="my-7">
            <MdLocationOn size={43} className="mb-3 text-[#cfb66c]" />
            <div className="max-w-xs">
              <a
                className="font-medium text-lg max-w-sm"
                href="tel:+2348097091229"
              >
                Suite 006, Swift Plaza, Plot 318, Agunwa Anaekwe Street, Wuye
                District, Abuja.
              </a>
            </div>
          </div>
          <div className="my-7">
            <BsFillEnvelopeFill size={43} className="mb-3 text-[#cfb66c]" />
            <div className="max-w-xs">
              <a
                className="font-medium text-lg max-w-sm"
                href="mailto:talktous@uoalc.com"
              >
                talktous@uoalc.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
