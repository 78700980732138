import React from "react";
import { Ad, Cards, ContactUsSection, Hero } from "../component/team";
import { ScrollToTop } from "../lib/utils";

const Team = () => {
  ScrollToTop();
  return (
    <>
      <main className="teamBg">
        <section className="py-[120px] bg-black  text-white bg-opacity-80">
          <Hero />
        </section>
      </main>
      <section className=" max-w-[1140px] mx-auto px-5 xl:px-0">
        <Cards />
      </section>
      <section className=" max-w-[1140px] pt-16 mx-auto px-5 xl:px-0">
        <Ad />
      </section>
      <section className="py-[50px] bg-[#f1f1f1] px-5 xl:px-0">
        <ContactUsSection />
      </section>
    </>
  );
};

export default Team;
