import React from "react";
import { EventCards, Hero } from "../component/events";
import { ScrollToTop } from "../lib/utils";

const Event = () => {
  ScrollToTop();
  return (
    <>
      <main className="teamBg">
        <section className="py-[120px] bg-black  text-white bg-opacity-80">
          <Hero />
        </section>
      </main>
      <section className=" pt-[120px] pb-16 max-w-[1140px] mx-auto px-5 xl:px-0">
        <EventCards />
      </section>
    </>
  );
};

export default Event;
