import React from "react";
import { Link } from "react-router-dom";

const Ad = () => {
  return (
    <section>
      <div className=" py-[120px]">
        <div className="flex flex-col justify-center items-center">
          <h1 className="lg:text-[3.5rem] text-[2.5rem] font-semibold mb-5 text-center sideFont">
            Why then would you gamble?
          </h1>
          <div className=" flex space-x-5">
            <Link to="/contact" className="button">
              Request a Lawyer
            </Link>
            <Link to="/contact" className="button">
              Book a consultation
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ad;
