import React from "react";
import { XHR } from "../../app/request";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ScrollToTop } from "../../lib/utils";

const SinglePublicationPage = () => {
  const navigate = useNavigate();
  let { slug } = useParams();
  const [publicationData, setPublicationData] = React.useState({});

  const fetchPublicationData = () => {
    XHR("get", `/publication/${slug}`)
      .then((res) => {
        setPublicationData(res.data[0]);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    fetchPublicationData();
  }, []);

  console.log(slug);
  ScrollToTop();
  return (
    <>
      <main className="teamBg">
        <section className="py-[120px] bg-black  text-white bg-opacity-80">
          <section className="max-w-[1140px] mx-auto flex justify-center items-baseline">
            <div className="px-5 xl:px-0 grow">
              {/* <div className="max-w-2xl mb-5 mt-10 mx-auto flex items-center justify-between space-x-10">
                <h6 className="shrink-0 font-semibold text-4xl">Back</h6>
                <span className="h-[3px] w-full bg-[#cfb66c]"></span>
              </div> */}
              <div className=" flex justify-center  mt-16">
                <div className=" text-center">
                  <h1 className="  font-semibold tracking-wider mb-5 sideFont uppercase text-lg">
                    {publicationData?.title}
                  </h1>
                </div>
              </div>
              <div className="flex justify-center items-center mt-6">
                <nav aria-label="Breadcrumb" class="flex">
                  <ol class="flex overflow-hidden rounded-lg border border-[#af9854] text-gray-600">
                    <li class="flex items-center">
                      <Link
                        to="/"
                        class="flex h-10 items-center cursor-pointer gap-1.5 bg-gray-100  px-4 transition hover:text-[#bba35c]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                          />
                        </svg>

                        <span class="ms-1.5 font-medium"> Home </span>
                      </Link>
                    </li>

                    <li class="relative flex items-center">
                      <span class="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>

                      <Link
                        to="/resources/publication"
                        class="flex h-10 items-center bg-white hover:bg-[#cfb66c] hover:text-white pe-4 ps-8 font-medium transition duration-500"
                      >
                        Publications
                      </Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </section>
        </section>
      </main>
      <section className="py-[120px] max-w-[1140px] mx-auto min-h-[50vh] px-5 xl:px-0">
        <div dangerouslySetInnerHTML={{ __html: publicationData?.body }} />
      </section>
    </>
  );
};

export default SinglePublicationPage;
