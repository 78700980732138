import React from "react";
import { About, Contact, Hero, Team, Testimonials } from "../component/home";
import { ScrollToTop } from "../lib/utils";
import { ImgUrl, XHR } from "../app/request";
// import Popup from '../component/popup';

const Home = () => {
  const [homePage, setHomePage] = React.useState({});
  const fetchHome = () => {
    XHR("get", "/homepages")
      .then((res) => {
        setHomePage(res.data[0]);
        console.log(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    fetchHome();
  }, []);
  ScrollToTop();
  return (
    <>
      
      <main className="heroBg">
        <section className=" bg-black  text-white bg-opacity-80 ">
          <Hero
            title={homePage?.hero_title}
            subTitle={homePage?.hero_subtitle}
          />
        </section>
      </main>
      <section className="py-[120px] max-w-[1140px] mx-auto px-5 xl:px-0">
        <About
          title={homePage?.about_us_title}
          subTitle={homePage?.about_us_content}
          photo={homePage?.about_us_photo}
        />
      </section>
      <main className="teamBg">
        <section className="py-[120px] bg-black text-white bg-opacity-80 px-5 xl:px-0">
          <Team />
        </section>
      </main>
      <section className="py-[120px] max-w-[1140px] mx-auto px-5 xl:px-0">
        <Testimonials content={homePage?.testimonial_content} />
      </section>
      <section className="py-[50px] bg-[#f1f1f1] px-5 xl:px-0">
        <Contact />
      </section>
    </>
  );
};

export default Home;
