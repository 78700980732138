import axios from "axios";
import { useState } from "react";

export const REQUEST_URI = `https://api.uoalc.com/`;

export const ImgUrl = "https://api.uoalc.com/images/";

const XHRDebug = (setting = false) => {
  if (setting === true) {
    axios.interceptors.request.use(
      (req) => {
        var data = req.method === "get" ? req.params : req.data;
        console.log(
          `Request method: '${req.method}', to ${
            req.url
          }, with data: ${JSON.stringify(data, true)}`
        );
        return req;
      },
      (err) => {
        return Promise.reject(err);
      }
    );

    axios.interceptors.response.use(
      (res) => {
        console.log(
          `Status: ${res.status}:${res.statusText} - Data: ${JSON.stringify(
            res.data,
            true
          )}`
        );
        return res;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
};

export const XHR = async (
  method,
  url,
  userdata = null,
  debugSetting = false
) => {
  XHRDebug(debugSetting);
  //   const auth = authStore.getRawState().auth || null;

  const auth = localStorage.getItem("auth") || null;
  return await axios({
    url: url,
    method: method,
    baseURL: REQUEST_URI + "api",
    headers: { Authorization: auth ? `Bearer ${auth}` : undefined },
    params: method === "get" ? userdata : undefined,
    data: method !== "get" ? userdata : undefined,
    timeout: 50000,
  });
};

export const useXHR = (
  method,
  url,
  userdata,
  trigger = true,
  debugSetting = false
) => {
  XHRDebug(debugSetting);
  const auth = localStorage.getItem("auth") || null;

  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  async function startRequest() {
    setIsLoading(true);
    try {
      setResponse({
        success: await axios({
          url: url,
          method: method,
          baseURL: REQUEST_URI,
          headers: { Authorization: auth ? `Bearer ${auth}` : undefined },
          params: method === "get" ? userdata : undefined,
          data: method !== "get" ? userdata : undefined,
          timeout: 10000,
        }),
      });
      setIsLoading(false);
    } catch (err) {
      setResponse({ error: err });
      setIsLoading(false);
    }
  }

  // if (trigger) startRequest();

  return { isLoading, response, startRequest };
};
