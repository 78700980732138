import React from "react";
import { Hero, NewslettersComponent } from "../component/newsletter";

const Newsletters = () => {
  return (
    <>
      <main className="teamBg">
        <section className="py-[120px] bg-black  text-white bg-opacity-80">
          <Hero />
        </section>
      </main>
      <section className=" pt-[120px] max-w-[1140px] mx-auto px-5 xl:px-0">
        <NewslettersComponent />
      </section>
    </>
  );
};

export default Newsletters;
